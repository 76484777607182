<template>
  <div ref="sectionRef" class="relative bg-blue-700">
    <div class="sticky top-0 z-2 bg-blue-700 pt-11 lg:pt-14">
      <div
        ref="headerProgramsContainer"
        class="container z-2 mb-6 flex flex-col justify-between overflow-y-hidden bg-blue-700 lg:mb-14 lg:flex-row"
      >
        <div
          v-if="data.headline"
          class="relative z-2 mb-3 bg-blue-700 text-2xl text-blue-100 lg:mb-0"
        >
          {{ data.headline }}
        </div>
        <ul
          v-if="data.programCategories"
          class="programs-title transition-transform duration-500"
          :style="{ '--translationNumber': `${titleTranslation}px` }"
        >
          <li
            v-for="(category, index) in data.programCategories"
            ref="titleRefs"
            :key="index"
            class="mb-3 cursor-pointer text-4xl lg:text-8xl"
            :class="[activeCategory === index ? 'text-light-gray' : 'text-light-gray-800']"
            @click="handleNavClick(index)"
          >
            <Heading semantic>
              {{ category.programCategory }}
            </Heading>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-for="(category, index) in data.programCategories"
      ref="categoryNodes"
      :key="index"
      class="relative bg-blue-700"
    >
      <div
        class="h-[17px] w-full bg-[url(/assets/images/backgrounds/slashes-white.png)] bg-repeat"
      />
      <div ref="textRefs" class="grid-default container pt-6 lg:pt-16">
        <div
          class="col-span-4 mb-1 text-base text-light-gray-200 lg:order-2 lg:col-start-5 lg:col-end-13 lg:mb-24 lg:text-2xl"
        >
          {{ category.description }}
        </div>
        <div ref="circleRefs" class="col-span-2 justify-self-end lg:order-1 lg:justify-self-auto">
          <CircleButton
            iconName="arrow-right"
            color="orange"
            :size="isDesktop ? 'lg' : 'md'"
            :to="
              localePathByType(
                category.categoryLink?.__typename as string,
                category.categoryLink?.slug as string
              )
            "
          />
        </div>
        <ul class="col-span-6 mb-3 lg:order-4 lg:col-start-9 lg:col-end-13 lg:mb-0">
          <li
            v-for="(product, idx) in getProductsPerCategory(category)"
            :key="idx"
            class="mt-3 border-b border-white border-opacity-20 pb-3 text-base text-blue-200 transition-colors duration-300 last:border-0 hover:text-white lg:mt-5 lg:pb-5 lg:text-2xl"
          >
            <NuxtLink :to="localePathByType(product.__typename as string, product.slug as string)">
              {{ product.product }}
            </NuxtLink>
          </li>
        </ul>
        <Image
          class="col-span-6 mx-auto aspect-square rounded-t-3xl lg:order-3 lg:col-start-3 lg:col-end-9"
          loading="lazy"
          :src="
            category.image!.responsiveImage
              ? category.image!.responsiveImage?.src
              : category.image!.url
          "
          :srcset="
            category.image!.responsiveImage
              ? category.image!.responsiveImage?.webpSrcSet
              : undefined
          "
          :width="
            category.image!.responsiveImage
              ? category.image!.responsiveImage?.width
              : category.image!.width
          "
          :height="
            category.image!.responsiveImage
              ? category.image!.responsiveImage?.height
              : category.image!.height
          "
          :sizes="category.image?.responsiveImage?.sizes"
          :alt="category.image!.alt!"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import {
  ProgramCategoryRecord,
  ProgramProductRecord,
  SectionProgramRecord
} from '~/types/generated'
import { theme } from '#tailwind-config'

const { localePathByType } = useLocalePathByType()

const { locale } = useI18n()
const { isDesktop } = useDevice()
const breakpoints = useBreakpoints(theme.screens)
const isScreenLg = breakpoints.greater('lg')
const activeCategory = ref(0)
const titleTranslation = ref(0)
const headerProgramsContainer = ref<HTMLElement | null>(null)
const circleRefs = ref<HTMLElement[] | null>(null)
const textRefs = ref<HTMLElement[] | null>(null)
const titleRefs = ref<HTMLElement[] | null>(null)
const sectionRef = ref<HTMLElement | null>()
const isPinned = ref(false)

const { data: products } = await useAsyncGql('programProducts', {
  // @ts-ignore
  locale
})

defineProps({
  data: {
    type: Object as PropType<SectionProgramRecord>,
    default: () => {}
  }
})

const categoryNodes = ref<HTMLElement[] | null>(null)

const { stop } = useIntersectionObserver(
  sectionRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      if (!isPinned.value && categoryNodes.value && titleRefs.value) {
        gsap.utils.toArray(categoryNodes.value).forEach((panel, i) => {
          if (i !== categoryNodes.value?.length - 1) {
            ScrollTrigger.create({
              trigger: panel as HTMLElement,
              start: 'top+=50px top',
              pin: true,
              pinSpacing: false,
              onEnter: () => {
                activeCategory.value = i

                if (i !== 0) {
                  titleTranslation.value -= (titleRefs.value[0].clientHeight + 24)
                }
              },
              onLeaveBack: () => {
                if (i !== 0) {
                  activeCategory.value = i - 1
                  titleTranslation.value += (titleRefs.value[0].clientHeight + 24)
                }
              }
            })
          } else {
            gsap.timeline({
              scrollTrigger: {
                trigger: panel as HTMLElement,
                start: 'center-=200px center',
                end: () => {
                  return '+=' + panel.height
                },
                onEnter: () => {
                  activeCategory.value = i

                  if (i !== 0) {
                    titleTranslation.value -= titleRefs.value[0].clientHeight
                  }
                },
                onLeaveBack: () => {
                  if (i !== 0) {
                    activeCategory.value = i - 1
                    titleTranslation.value += titleRefs.value[0].clientHeight
                  }
                }
              }
            })
          }
        })

        ScrollTrigger.refresh()
      }

      isPinned.value = true
    }
  },
  {
    threshold: 0.1
  }
)

function getProductsPerCategory(category: ProgramCategoryRecord) {
  return products.value.allProgramProducts.filter(
    (el: ProgramProductRecord) => el.category?.id === category.id
  )
}

function handleNavClick(idx: number) {
  activeCategory.value = idx

  if (categoryNodes.value) {
    gsap.to(window, {
      duration: 1,
      scrollTo: categoryNodes.value?.[idx],
      ease: 'power3.inOut'
    })
  }
}

onMounted(async () => {
  await nextTick(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

    if (circleRefs.value && textRefs.value && headerProgramsContainer.value && titleRefs.value) {
      const tlHeader = gsap.timeline({
        scrollTrigger: {
          trigger: headerProgramsContainer.value,
          scrub: true
        }
      })
      const headerHeight =
        headerProgramsContainer.value.clientHeight - ((titleRefs.value[2].clientHeight + 12) * 2)

      tlHeader.to(headerProgramsContainer.value, {
        height: `${headerHeight}px`
      })

      if (isScreenLg.value) {
        gsap.utils.toArray(circleRefs.value).forEach(layer => {
          const tlCircle = gsap.timeline({
            scrollTrigger: {
              trigger: layer as HTMLElement,
              start: 'top 50%',
              end: 'bottom 40%',
              scrub: true
            }
          })

          tlCircle.to(layer, {
            y: '230px',
            duration: 1
          })
        })
      }
    }
  })
})

onUnmounted(() => {
  stop()

  ScrollTrigger.getAll().forEach(trigger => {
    trigger.kill()
  })
})
</script>

<style lang="postcss" scoped>
.programs-title {
  @apply translate-y-[var(--translationNumber)];
}
</style>
